/* Theme Colors */
$base-blue: #000091;
$base-grey-1: #edeeef;
$base-grey-2: #697074;
$base-white: #ffffff;
$base-purple: #6e156e;
$base-orange: #e4821a;
$base-red: #d71a3a;
$grid-breakpoints: (
  xs: 0,
  xs-up: 1,
  sm: 576px,
  sm-up: 578px,
  md: 768px,
  md-up: 769px,
  lg: 992px,
  lg-up: 993px,
  xl: 1200px,
  xl-up: 1201px,
  xxl: 1400px,
  xxl-up: 1401px,
  desk: 1920px,
  desk-up: 1921px,
  4k: 2560px,
  4k-up: 2561px,
  wqhd: 3440px,
  wqhd-up: 3441px,
);
