/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,600,700,700italic,900italic,900");
@import "_variables.scss";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

* {
  font-family: Roboto;
}

html,
body {
  overflow-x: hidden;
  background-attachment: fixed;
}

app-footer {
  margin: 45px 0 0 0;
}

.dx-accordion-item-title {
  background-color: $base-white;
}

.dx-accordion-item-body {
  background-color: rgba($color: $base-white, $alpha: 0.5);
}

.dx-accordion:first-child {
  margin-bottom: 25px;
}

.dx-searchbox .dx-icon-search {
  right: 0;
}

.dx-searchbox .dx-placeholder::before,
.dx-searchbox .dx-texteditor-input {
  padding: 13px;
  padding-right: 34px;
  font-size: 20px;
}

.dx-texteditor-input {
  border: 0;
  border-bottom: 2px solid $base-blue;
  background-color: $base-grey-1;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.dx-texteditor.dx-editor-outlined {
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.dx-list-item-content {
  white-space: initial;
}

.dx-list.dx-list-with-search .dx-scrollable-wrapper {
  height: calc(50vh - 84px);
}

@include media-breakpoint-up(xxl-up) {
  .dx-list.dx-list-with-search .dx-scrollable-wrapper {
    height: calc(60vh - 84px);
  }

  .dx-scrollable-native.dx-scrollable-native-ios .dx-scrollable-content {
    min-height: 100%;
    box-sizing: content-box;
  }
}

//buttons
.btn {
  padding: 10px 34px;
  font-size: 20px;
}

.btn-light {
  border: 1px solid $base-blue;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: $base-white;
  color: $base-blue;
}

.btn-light:hover {
  border: 1px solid $base-blue;
  background-color: $base-blue;
  color: $base-white;
}

.btn-blue {
  border: 1px solid $base-blue;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: $base-blue;
  color: $base-white;
}

.btn-blue:hover {
  border: 1px solid $base-blue;
  background-color: $base-white;
  color: $base-blue;
}

.btn-plus {
  padding-left: 20px;
  position: relative;
  cursor: pointer;
}

.btn-plus:hover {
  font-weight: bold;
  text-decoration: underline;
}

.btn-plus::before {
  display: block;
  content: "+";
  position: absolute;
  left: 10px;
  top: 0;
}

.btn-icon {
  min-width: 151px;
}

.btn.inactive {
  background-color: $base-grey-2;
  border-color: $base-grey-2;
}

//multiview
.dx-multiview {
  height: 600px;
}

@include media-breakpoint-down(md) {
  .dx-multiview {
    height: inherit;
  }

  .multiview-item {
    padding-bottom: 80px;
  }
}

body {
  background: url(/assets/images/dashboard/dashboard-background.jpg);
  background-size: cover;
  background-attachment: fixed;
}

.evaluation-answers {
  .wrong-icon {
    margin-right: 5px;
  }

  .dx-field-label {
    float: unset;
    width: unset;
  }

  .dx-field-value-static {
    float: unset;
    width: unset;
  }
}

.popup-headline {
  font-size: 20px;
  margin-bottom: 15px;
}

.popup-description {
  font-size: 14px;
  font-weight: 400;
}

.popup-button-container {
  position: absolute;
  bottom: 20px;
}

.popup-buttons .dx-popup-content {
  padding-bottom: 72px;

  .btn:first-child {
    margin-right: 5px;
  }
}

:focus-visible,
:focus {
  outline: 0;
}

.dx-accordion-item-title::before {
  display: none;
}

.dx-pages .dx-texteditor-input {
  padding: 0;
}

.dx-pager .dx-pages .dx-page.dx-selection {
  font-weight: bold;
}

.accordion-chat {
  margin-bottom: 25px;

  .dx-accordion-item-closed {
    height: 64px !important;
  }
}

.dx-dropdownlist-popup-wrapper .dx-list .dx-scrollable-content {
  background-color: $base-white;
}

.dx-tagbox-only-select .dx-dropdowneditor-input-wrapper {
  border: 0;
  border-bottom: 2px solid #000091;
  background-color: #edeeef;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

/*.dx-tagbox-only-select .dx-dropdowneditor-input-wrapper::after {
  content: "";
  display: block;
  width: 18px;
  height: 10px;
  background-image: url(/assets/images/icons/chevron-down.svg);
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}*/

.dx-texteditor-input {
  min-height: 52px !important;
}

.qualTagBox .dx-texteditor-input,
.qualTagBox .dx-texteditor-container {
  background-color: #edeeef;

  width: 100%;
}

.dx-button-has-text.dx-fileuploader-button .dx-button-content {
  padding: 10px 34px;
  font-size: 20px;
  border: 1px solid $base-blue;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: $base-blue;
  color: $base-white;
}

.dx-button-has-text.dx-fileuploader-button .dx-button-content:hover {
  border: 1px solid $base-blue;
  background-color: $base-white;
  color: $base-blue;
}

.btn-light-wrapper .dx-button-content {
  padding: 10px 34px;
  font-size: 20px;
  border: 1px solid $base-blue;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: $base-blue;
  color: $base-white;
}

.btn-light-wrapper .dx-button-content:hover {
  border: 1px solid $base-blue;
  background-color: $base-white;
  color: $base-blue;
}

.btn-blue-wrapper .dx-button-content {
  padding: 10px 34px;
  font-size: 20px;
  border: 1px solid $base-blue;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  background-color: $base-blue;
  color: $base-white;
}

.btn-blue-wrapper .dx-button-content:hover {
  border: 1px solid $base-blue;
  background-color: $base-white;
  color: $base-blue;
}

.buttons-group {
  margin-top: 15px;

  .dx-box-flex {
    display: inline-flex !important;
    flex-direction: inherit !important;
    width: 100% !important;
    height: 100% !important;
    justify-content: flex-start !important;
    align-items: baseline !important;

    .dx-box-item {
      display: flex !important;

      .dx-item-content {
        display: flex !important;
      }
    }
  }
}

.btn-light-wrapper {

  &.dx-button-has-text .dx-button-content,
  .dx-button-has-text .dx-button-content {
    border: 1px solid $base-blue;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: $base-white;
    color: $base-blue;
  }

  &.dx-button-has-text .dx-button-content:hover,
  .dx-button-has-text .dx-button-content:hover {
    border: 1px solid $base-blue;
    background-color: $base-blue;
    color: $base-white;
  }
}

.dx-popup-wrapper>.dx-overlay-content {
  background-color: $base-white;
}

/*
.dx-tag {
  height: 100%;
}

.dx-tag-content {
  height: 100%;
  span {
    position: relative;
    top: 14px;
  }
}*/

.dx-datagrid .dx-column-lines>td {
  border-left: none;
  border-right: none;
}

@include media-breakpoint-down(sm) {
  #employee-detail-container .buttons-group .dx-box-flex {
    display: block !important;
  }
}

.dx-fileuploader-wrapper {
  padding: 0;
}

.dx-fileuploader-input-wrapper {
  border: 0;
}

.list-element-clock {
  .list-left {
    .list-head {
      font-size: 14px;
      font-weight: bold;
      color: $base-purple;
    }

    .list-body {
      font-size: 14px;
      color: $base-purple;
    }
  }

  .list-left {
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: middle;
  }

  .list-right {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
  }
}

.list-element-warning {
  .list-left {
    .list-head {
      font-size: 14px;
      font-weight: bold;
      color: $base-orange;
    }

    .list-body {
      font-size: 14px;
      color: $base-orange;
    }
  }

  .list-left {
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: middle;
  }

  .list-right {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
  }
}

.list-element-escalation {
  .list-left {
    .list-head {
      font-size: 14px;
      font-weight: bold;
      color: $base-red;
    }

    .list-body {
      font-size: 14px;
      color: $base-red;
    }
  }

  .list-left {
    width: calc(100% - 30px);
    display: inline-block;
    vertical-align: middle;
  }

  .list-right {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
  }
}

.tag-box-filtered {
  border-bottom: 2px solid #000091;
}

.tag-box-filtered input {
  border-bottom: 0;
}

.tag-box-filtered .dx-tag-container.dx-texteditor-input-container {
  background-color: $base-grey-1;
}

.dx-item.dx-toolbar-item.dx-toolbar-label {
  max-width: unset !important;
}